<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->
		<div class="tab-content my-3" id="v-pills-tabContent">
			<div class="tab-pane animated fadeInUpShort show active go" id="v-pills-all" role="tabpanel" aria-labelledby="v-pills-all-tab">
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">
								
								<div class="row">
									<div class="col-md-12">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<!-- <input v-model="query" class="form-control p-2" placeholder="搜索" type="text"> -->
											
											<span>
												<select v-model="dept_id" class="form-control  ml-2">
													<option value="0">所有企业</option>
													<option v-bind:key="index" 
														:value="item.id" v-for="(item,index) in departmentlist">
														{{item.department_name}}</option>
												</select>
											</span>
											
											<!-- <div class="input-group">
												<input v-model="start_date" id="date_timepicker_start" type="text" class="date-time-picker form-control" style="width: 105px;">
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											<div class="ml-2">
											<date-picker
												  v-model="start_date"
												  format="YYYY-MM-DD"
												  value-type="format" 
												  input-class="form-control" 
												  :editable="false" 
												  :clearable="false" 
												></date-picker>
											</div>	
											
											<!-- <div class="input-group ml-2">
												<input v-model="end_date" id="date_timepicker_end" type="text" class="date-time-picker form-control" style="width: 105px;" >
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											<div class="ml-2">
											<date-picker
												  v-model="end_date"
												  format="YYYY-MM-DD"
												  value-type="format" 
												  input-class="form-control" 
												  :editable="false" 
												  :clearable="false" 
												></date-picker>
											</div>
										
											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i class="icon-search"></i>
											</button>
										</div>
										<!-- 搜索 end -->
									</div>
									<!-- <div class="col-md-3">
										<div class="float-right m-3">
											 <button type="button" class="btn btn-secondary">导出</button>
										</div>
									</div>	 -->
								</div>	
							</div>
	
							<div class="">
							
							    <div class="row my-3">
									<div class="col-md-4">
										<div class=" card no-b">
											<!-- <div class="card-header">
												<h1>充值</h1>
											</div> -->
											<div class="card-body">
												<div class="d-flex justify-content-between align-items-center">
													<div><h3>充值</h3></div>
													<div><span class="badge badge-pill badge-success s-18">{{pay.amount}}元</span></div>
												</div>
												<hr>
												
												<table class="table table-hover earning-box">
													<tbody>
													<!-- <template v-for="(item, index) in list"> -->
													<tr class="no-b">
														<td>
															<h6>笔数</h6>
															<!-- <small class="text-muted">{{quota.count}}笔</small> -->
														</td>
														<td class="text-right">{{pay.count}}笔</td>
													</tr>
													<!-- </template>	 -->
													</tbody>
												</table>
											</div>
										</div>
									</div>
							        <div class="col-md-4">
										<div class=" card no-b">
											<!-- <div class="card-header">
												<h1>充值</h1>
											</div> -->
											<div class="card-body">
												<div class="d-flex justify-content-between align-items-center">
													<div><h3>发放</h3></div>
													<div><span class="badge badge-pill badge-warning s-18">{{quota.amount}}元</span></div>
												</div>
												<hr>
												
												<table class="table table-hover earning-box">
													<tbody>
													<!-- <template v-for="(item, index) in list"> -->
													<tr class="no-b">
														<td>
															<h6>笔数</h6>
															<!-- <small class="text-muted">{{quota.count}}笔</small> -->
														</td>
														<td class="text-right">{{quota.count}}笔</td>
													</tr>
													<!-- </template>	 -->
													</tbody>
												</table>
											</div>
										</div>
									</div>
									
								</div>		
							</div>
							
							
						</div>
					</div>
				</div>
	
	
			</div>
	
		</div>	
		<!-- //main content -->
	</div>
</template>

<script>
	const __payment = {
	  'PC': '后台',
	  'WEAPP': '自助',
	}
	
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data(){
			return{
				title: '充值订单列表',
				
				token: '',
				
				start_date: '',
				end_date: '',
				
				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				
				quota: {},//发送
				pay: {},//充值
			}
		},
		
		computed: {
			
			// activeCustomerGroups: function () {
			// 	let self = this;
			// 	return this.customergrouplist.filter(function (item) {
			// 		return item.outlet_id == self.outlet_id;//返回本店的
			// 	})
			// }
		},
		
		components: {
			DatePicker
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
		},
		
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			if (!self.token) {
				console.log('--- mounted jump  --- ')
				location.href = 'index.html';
				return;
			}
			
			self.init(() => {
				self.initData();
			});
		},
		methods: {
			
			init(callback) {
    		let self = this;
    	
    		//
    		this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
    				params: {
    					token: self.token,
    					dept_id: self.dept_id,
    					// query: self.query,
    					// current_page: self.current_page,
    					// page_size: self.page_size,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				if (data.code == 200) {
    					self.departmentlist = data.data.departments;
						
						for (let i = 0; i < self.departmentlist.length; i++) {
							let dept = self.departmentlist[i];
							self.deptmap[dept.id] = dept;
						}
						console.log('--- dept map ->', self.deptmap);
						
						self.current_date = data.data.current_date;
						
						if (typeof callback == 'function') {
						    callback()
						}
    				} 
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
			
			initData(){
				let self = this;
				
				//取得业务数据
				self.axios.get(self.GLOBAL.baseURI + 'getQuotaReport', {
					params: {
						token: self.token,
						dept_id: self.dept_id,
						start_date: self.start_date,
						end_date: self.end_date,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) { 
						
						self.pay = data.data.pay;
						self.quota = data.data.used;
						
						self.start_date = data.data.start_date;
						self.end_date = data.data.end_date;
					}
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) { 
						//登录超时
						self.logoff();
					}
					else{
						alert(data.message)
					}
				});
				//END
				
			},
			
			pagechange:function(currentPage){
			   console.log(currentPage);
			   // ajax请求, 向后台发送 currentPage, 来获取对应的数据
			   this.current_page = currentPage;
			   this.initData();
			 },
			 
			search() {
				let self = this;
			
				self.initData();
			},
			
			view(index){
				console.log(index);
				let self = this;
				
				let rechargeorder = self.rechargeorderlist[index];
				console.log(index,rechargeorder);
				
				self.selectedIndex = index;
				self.rechargeorder = rechargeorder;
				
				// self.getSaleOrder(()=>{
				// 	$('#hModal').modal('show');
				// });
			},
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
			
				self.init(() => {
					self.initData();
					self.group_id = 0;
				});
			},
			
		},
	}
	
</script>

<style>
	.mx-icon-calendar, .mx-icon-clear{
		right: 28px;
	}
</style>
