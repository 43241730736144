import { render, staticRenderFns } from "./quotareport.vue?vue&type=template&id=34f361e3&"
import script from "./quotareport.vue?vue&type=script&lang=js&"
export * from "./quotareport.vue?vue&type=script&lang=js&"
import style0 from "./quotareport.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports